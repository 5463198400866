export const USER_STATUSES = {
  init: 'init',
  active: 'active',
  frozen: 'frozen',
  closed: 'closed',
};

export type USER_STATUS_TYPE = typeof USER_STATUSES[keyof typeof USER_STATUSES];

export const TRANSACTION_STAGE = {
  created: 'created',
  pending: 'pending',
  settled: 'settled',
  replaced: 'replaced',
  signed: 'signed',
  failed: 'failed',
  refunded: 'refunded',
  voided: 'voided',
  declined: 'declined',
} as const;

export const TRANSACTION_STATUSES = {
  ok: 'ok',
  config_error: 'config_error',
  pending: 'pending',
  timeout: 'timeout',
  error: 'error',
  failed: 'failed',
} as const;

export const SUBMISSION_STATUSES = {
  created: 'created',
  newTier: 'new_tier',
  pending: 'pending',
  applying: 'applying',
  declined: 'declined',
  done: 'done',
  onBoarding: 'onboarding',
} as const;

export const SPLITIT_APPLICATION_STAGES = {
  created: 'created',
  funded: 'funded',
  closed: 'closed',
  canceled: 'canceled',
  expired: 'expired',
  error: 'error',
  config_error: 'config_error',
} as const;

export const LENDER_APPLICATION_STAGES = {
  created: 'created',
  preApproved: 'preapproved',
  approved: 'approved',
};

export const LENDER_APPLICATION_STATUSES = {
  ok: 'ok',
  declined: 'declined',
  pending: 'pending',
  callback: 'callback',
  missingInformation: 'missing_information',
  frozen_credit: 'frozen_credit',
  expired: 'expired',
  canceled: 'canceled',
  error: 'error',
  timeout: 'timeout',
  configError: 'config_error',
  validationError: 'validation_error',
  locked: 'locked',
  notEligible: 'not_eligible',
  partialData: 'partial_data',
  ongoingAppExists: 'ongoing_app_exists',
  suspended: 'suspended',
  temporaryError: 'temporary_error',
};

export const LENDER_TYPES = {
  installment: 'installment',
  revolving: 'revolving',
  none: 'none',
} as const;

export type LENDER_PAYMENT_TYPE = typeof LENDER_TYPES[keyof typeof LENDER_TYPES];

export const ADMIN_APPLICATIONS_STATUSES = {
  approved: 'approved',
  expired: 'expired',
  declined: 'declined',
  error: 'error',
  pending: 'pending',
};

export type ADMIN_APPLICATIONS_STATUSES_TYPE = typeof ADMIN_APPLICATIONS_STATUSES[
  keyof typeof ADMIN_APPLICATIONS_STATUSES
  ];

export enum ALLOWED_MODE {
  prod = 'production',
  dev = 'dev',
  qa = 'qa',
  training = 'training',
  demo = 'demo'
}

export const LENDERS = {
  greensky: 'greensky',
  fortiva: 'fortiva',
  patientfi: 'patientfi',
  proceed: 'proceed',
  claritypay: 'claritypay',
  aff: 'aff',
  allegro: 'allegro',
  splitit: 'splitit',
  uga2: 'uga2',
  uga3: 'uga3',
  uga_prime: 'uga_prime',
  cleargage: 'cleargage',
  hfd: 'hfd',
  hfd_second: 'hfd_second',
  alphaeon2: 'alphaeon2',
  greatamerica: 'greatamerica',
  hfd_servicing: 'hfd_servicing',
  varidi: 'varidi',
  genesis: 'genesis',
  powerpay: 'powerpay',
  ally: 'ally',
  ideal: 'ideal',
  covered_care: 'covered_care',
  covered_prime: 'covered_prime',
  covered_extra: 'covered_extra',
  carecredit: 'carecredit',
} as const;

export type LENDERS_TYPE = typeof LENDERS[keyof typeof LENDERS];

export const LINK_BASED_LENDERS: Array<LENDERS_TYPE> = [
  LENDERS.allegro,
  LENDERS.aff,
  LENDERS.uga2,
  LENDERS.uga3,
  LENDERS.uga_prime,
  LENDERS.greatamerica,
  LENDERS.varidi,
  LENDERS.patientfi,
  LENDERS.covered_care,
  LENDERS.covered_extra,
  LENDERS.covered_prime,
  LENDERS.ally,
  LENDERS.claritypay,
];

export const HfdRiskPipeline = {
  GuaranteeApproval: 'GuaranteeApproval',
  GuaranteeApprovalWithTransUnion: 'GuaranteeApprovalWithTransUnion',
  None: 'None',
};

export const HfdRiskPipelineOptions = [
  {
    label: HfdRiskPipeline.GuaranteeApproval,
    value: HfdRiskPipeline.GuaranteeApproval,
  },
  {
    label: HfdRiskPipeline.GuaranteeApprovalWithTransUnion,
    value: HfdRiskPipeline.GuaranteeApprovalWithTransUnion,
  },
  {
    label: HfdRiskPipeline.None,
    value: HfdRiskPipeline.None,
  },
];

export const CLOSED_LENDERS = [LENDERS.greensky, LENDERS.cleargage, LENDERS.greatamerica];
